import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { typeAHeadCounties, countries } from "~/utils/Countries";
import usePrevious from "~/utils/usePrevious";

const Tel = (props) => {
  const {
    label,
    className,
    name,
    id,
    initialCountry,
    preferredCountries,
    autoComplete,
    autoFocus,
    required,
    onChange,
    setPhoneCountry,
    value,
    customErrorMessage,
    customValidation,
    inputStyle,
    buttonStyle,
    checkValidation,
    inputRef,
    onFocus,
    setPhoneIsValid,
    showTopLabel,
    noActiveLabel,
    placeholder,
    wrapperStyle,
    inlineFunnel,
    doNotValidate,
  } = props;

  // Refs
  const dropdownButtonEl = useRef();
  const dropdownEl = useRef();
  const searchInputEl = useRef();
  const inputEl = inputRef || useRef();

  // States
  const [internalID] = useState(() => {
    if (id) return id;
    return name;
  });
  const [country, setCountry] = useState(
    countries[initialCountry.toUpperCase()]
  );

  const [internalValue, setInternalValue] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [activeLabel, setActiveLabel] = useState(() => {
    return internalValue?.length >= 1;
  });
  const [active, setActive] = useState(false);
  const [firstFocus, setFirstFocus] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState();
  const [cursor, setCursor] = useState((internalValue?.length || 1) - 1);

  const previousStates = usePrevious({ country, internalValue });

  // Effects

  useEffect(() => {
    if (value?.length >= 1) {
      setActive(false);
      if (value?.length < 1) {
        setActiveLabel(false);
      }

      if (
        !checkLength(value, country) ||
        !customValidation({ country, internalValue })
      ) {
        setValid(false);
      } else {
        setValid(true);
        setErrors([]);
      }
    }
  }, []);

  useEffect(() => {
    if (value?.length >= 1) {
      setPhoneIsValid(checkLength(value, country));
    }
  });

  useEffect(() => {
    if (inputRef?.current && checkValidation) {
      if (!inputRef.current.checkValidity()) {
        setValid(false);
        errorMessages(inputRef.current);
      } else {
        setValid(true);
        setErrors([]);
      }
    }
  }, [checkValidation]);

  useEffect(() => {
    if (dropdownActive) {
      document.addEventListener("keydown", focusOnSearch);
    } else {
      document.removeEventListener("keydown", focusOnSearch);
      focusOnInput();
    }
  }, [dropdownActive]);

  useEffect(() => {
    if (!firstFocus) {
      return;
    }

    const input = inputEl.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [cursor]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      setPhoneCountry(country.countryCode);
      if (previousStates?.country) {
        let newNumber = swapCountries(
          internalValue,
          previousStates.country,
          country
        );
        const [displayPhone, e164Phone] = formatPhoneNumber(newNumber, country);
        setInternalValue(displayPhone);
        onChange(null, e164Phone, displayPhone, country?.phone[0]);
      }
    }
  }, [country]);

  useEffect(() => {
    if (value === "" || value === null) {
      setInternalValue("");
      setActiveLabel(false);
      setActive(false);
      setValid(false);
    } else if (
      !doNotValidate &&
      internalValue == "" &&
      (value !== "" || value !== null)
    ) {
      const savedCountry = countries[initialCountry.toUpperCase()];
      setCountry(countries[initialCountry.toUpperCase()]);
      const [displayPhone, e164Phone] = formatPhoneNumber(value, savedCountry);
      setInternalValue(displayPhone);
      if (
        !checkLength(displayPhone, savedCountry) ||
        !customValidation({ savedCountry, displayPhone })
      ) {
        setValid(false);
        const errorMessages = [];
        errorMessages.push({
          message: `Please enter a valid ${label.toLowerCase()}`,
          type: "patternMismatch",
        });
        setErrors(errorMessages);
      } else {
        setValid(true);
        setErrors([]);
      }

      onChange(null, e164Phone, displayPhone, country?.phone[0]);
    }
  }, [value]);

  useEffect(() => {
    if (internalValue?.length >= 1) setActiveLabel(true);
  }, [internalValue]);

  useEffect(() => {
    if (value?.length >= 1) {
      const [displayPhone, e164Phone] = formatPhoneNumber(value, country);
      setInternalValue(displayPhone);
      setActiveLabel(true);

      onChange(null, e164Phone, displayPhone, country?.phone[0]);
    }
  }, []);

  // Handlers
  const handleClick = () => {
    setActiveLabel(true);
    setActive(true);
    if (!firstFocus) {
      setFirstFocus(true);
    }
  };

  const handleFocus = (e) => {
    setActiveLabel(true);
    setActive(true);
    if (!firstFocus) {
      setFirstFocus(true);
    }
    onFocus(e);
  };

  const handleBlur = (e) => {
    setActive(false);
    if (internalValue?.length < 1) {
      setActiveLabel(false);
    }

    if (
      !e.target.validity.valid ||
      !checkLength(internalValue, country) ||
      !customValidation({ country, internalValue })
    ) {
      setValid(false);
      errorMessages(e.target);
    } else {
      setValid(true);
      setErrors([]);
    }
  };

  const handleButton = () => {
    setDropdownActive(!dropdownActive);
    if (!firstFocus) {
      setFirstFocus(true);
    }
  };

  const handleKeys = (e) => {
    let start = inputEl.current.selectionStart;
    let end = inputEl.current.selectionEnd;

    if (start != end) {
      return;
    }

    if (start < country.countryCode.length + 1) {
      start = country.countryCode.length + 1;
    }
    inputEl.current.setSelectionRange(start, start);
  };

  const handleChange = (e) => {
    const input = e.target.value;

    // Lets not update anything if we have a full number already
    if (
      filterOutCountryCode(e.target.value, country).split("").length - 1 >=
      country?.phoneFormat.split(".").length - 1
    ) {
      setCursor(inputEl.current.selectionStart - 1);
      return;
    }

    if (
      filterOutCountryCode(e.target.value, country).length > 1 &&
      /^\d+$/.test(input[inputEl.current.selectionStart + 1])
    ) {
      setCursor(inputEl.current.selectionStart);
    } else {
      // setCursor(inputEl.current.selectionStart + 1);
    }

    const [displayPhone, e164Phone] = formatPhoneNumber(input, country);
    setInternalValue(displayPhone);
    setValid(true);
    if (
      e.target.validity.valid &&
      customValidation({ country, internalValue })
    ) {
      setValid(true);
      setErrors([]);
    }
    onChange(e, e164Phone, displayPhone, country.countryCode);
  };

  const swapCountries = (number, newCountry, oldCountry) => {
    number = "+" + number.replace(/\D/g, "").trim();
    number = number.replace(
      `+${newCountry.phone[0]}`,
      `+${oldCountry.phone[0]}`
    );

    return number;
  };

  const checkLength = (value, country) => {
    let number = "+" + value.replace(/\D/g, "").trim();
    number = filterOutCountryCode(number, country);
    if (number.length == targetLength(country)) return true;
    return false;
  };

  const targetLength = (country) => {
    return country?.phoneFormat.split(".").length - 1 || 15;
  };

  const handlePaste = (e) => {
    let data = e.clipboardData.getData("text/plain").trim();

    if (!data.includes("+")) data = "+" + data;

    const [displayPhone, e164Phone] = formatPhoneNumber(data, country);
    setInternalValue(displayPhone);
    setActiveLabel(true);
    onChange(null, e164Phone, displayPhone, country?.phone[0]);
  };

  /**
   * errorMessages
   *
   * adds error messages if input is invalid.
   *
   * @param {Element} el
   */
  const errorMessages = (el) => {
    let errorMessages = [];

    if (el.validity.valueMissing) {
      errorMessages.push({
        message: `Please enter your ${label.toLowerCase()}`,
        type: "valueMissing",
      });
    }

    if (el.validity.typeMismatch && pattern === null) {
      errorMessages.push({
        message: `Please enter a ${label.toLowerCase()}`,
        type: "typeMismatch",
      });
    }

    if (
      !doNotValidate &&
      internalValue?.length >= 1 &&
      !checkLength(internalValue, country)
    ) {
      errorMessages.push({
        message: `Please enter a valid ${label.toLowerCase()}`,
        type: "patternMismatch",
      });
    }

    errorMessages = customErrorMessage({
      errorMessages,
      el,
      label,
      name,
      internalValue,
      country,
    });

    setErrors(errorMessages);
  };

  const formatPhoneNumber = (input, country) => {
    if (
      inputEl.current.value.includes("(") &&
      !inputEl.current.value.includes(")")
    ) {
      input = input.slice(0, -1);
    }

    input = filterOutCountryCode(input, country);

    let e164Phone = "+" + country?.phone[0] + input;

    if (input.length > country?.phoneFormat.split(".").length - 1)
      e164Phone = e164Phone.slice(0, -1);

    const displayPhone =
      "+" + country?.phone[0] + " " + applyFormat(input, country?.phoneFormat);

    return [displayPhone, e164Phone];
  };

  const filterOutCountryCode = (input, country) => {
    // Filters out pasted country code
    if (input.includes("+" + country?.phone[0])) {
      input = input.replace("+" + country?.phone[0], "");
    }
    return input.trim().replace(/[^0-9]/g, "");
  };

  const applyFormat = (input, format) => {
    let output = format;

    // replace all .'s with correct numbers
    input.split("").forEach((item) => {
      output = output?.replace(".", item);
    });

    // This is all clean up just incase we don't have enough numbers to apply the full mask
    if (output?.includes(".-") || output?.includes("-."))
      output = output?.replaceAll("-", "");
    if (output.includes(".)")) output = output?.replaceAll(".)", ".");

    if (output.includes("(") && !output.includes(")"))
      output = output?.replaceAll("(", "").replaceAll(")", "");

    output = output?.replaceAll(".", "").trim();

    return output;
  };

  // Break up the main component into small parts

  const focusOnSearch = () => {
    if (
      searchInputEl.current != null &&
      document.activeElement != searchInputEl.current
    )
      searchInputEl.current.focus();
  };

  const focusOnInput = () => {
    if (
      inputEl.current != null &&
      document.activeElement != inputEl.current &&
      firstFocus
    )
      inputEl.current.focus();
  };

  const countrySelector = () => {
    const [typeahead, setTypeahead] = useState("");
    const [search, setSearch] = useState("");
    const [typeaheadList, setTypeaheadList] = useState();
    const [typeaheadIndex, setTypeaheadIndex] = useState(0);

    const closeDropdown = (e) => {
      if (dropdownEl.current == null || dropdownButtonEl.current == null)
        return;
      if (
        !dropdownEl.current.contains(e.target) &&
        dropdownButtonEl.current != e.target
      ) {
        setDropdownActive(false);
      }
    };

    useEffect(() => {
      document.addEventListener("click", closeDropdown);
      return () => document.removeEventListener("click", closeDropdown);
    }, []);

    useEffect(() => {
      setTypeaheadIndex(0);
    }, [typeaheadList]);

    useEffect(() => {
      if (typeaheadList?.length >= 2) {
        setTypeahead(typeaheadList[typeaheadIndex]);
      }
    }, [typeaheadIndex]);

    const handleChange = (e) => {
      const input = e.target.value;
      let typeahead = "";
      const typeaheadList = typeAHeadCounties(input);
      setTypeaheadList(typeaheadList);

      if (typeaheadList.length >= 1) {
        // case insensitive typeahead
        typeaheadList[0].name.split("").forEach((value, key) => {
          if (value.toLowerCase() == input[key]) {
            typeahead = typeahead + value.toLowerCase();
          } else if (value.toUpperCase() == input[key]) {
            typeahead = typeahead + value.toUpperCase();
          } else {
            typeahead = typeahead + value;
          }
        });
        setTypeahead(typeahead);
      } else {
        setTypeahead(typeahead);
      }
      setSearch(e.target.value);
    };

    const handleKeys = (e) => {
      if (e.key == "ArrowRight" && typeahead != "") {
        if (typeaheadList?.length >= 1) {
          setTypeahead(typeaheadList[0].name);
          setSearch(typeaheadList[0].name);
        } else {
          setTypeahead("");
        }
      } else if (e.key == "Enter" && typeaheadList?.length >= 1) {
        changeCountry(typeaheadList[0]);
      } else if (
        e.key == "DownArrow" &&
        typeaheadList?.length >= 2 &&
        typeaheadIndex < typeaheadList?.length
      ) {
        setTypeaheadIndex(typeaheadIndex + 1);
      } else if (
        e.key == "DownArrow" &&
        typeaheadList?.length >= 2 &&
        typeaheadIndex >= typeaheadList?.length
      ) {
        setTypeaheadIndex(0);
      }
    };

    const changeCountry = (country) => {
      setCountry(country);
      setDropdownActive(false);
      setSearch("");
      setTypeahead("");
      setTypeaheadList([]);
    };

    const countryList = (countries) => {
      return (
        <ul className="Tel__dropdown-section">
          {countries.map((countryObj) => (
            <li key={`countries-${countryObj.name}`}>
              <button
                type="button"
                className={`${countryObj == country && "--active"}`}
                onClick={() => {
                  changeCountry(countryObj);
                }}
              >
                <div className="Tel__dropdown-flag">{countryObj.emoji}</div>
                <div className="Tel__dropdown-label">
                  {countryObj.name} (+{countryObj.phone[0]})
                </div>
              </button>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div
        className={`Tel__dropdown ${dropdownActive ? "--active" : ""}`}
        ref={dropdownEl}
      >
        <div className="Tel__search">
          <input
            ref={searchInputEl}
            onChange={handleChange}
            className="Tel__search-input"
            placeholder="Search for countries"
            value={search}
            onKeyDown={handleKeys}
          />
          <div className="Tel__search-type-head">{typeahead}</div>
        </div>
        {search == "" && (
          <>
            {preferredCountries?.length >= 1 &&
              countryList(
                [...countries].filter((country) => {
                  return preferredCountries.includes(country.countryCode);
                })
              )}
            {countryList([...countries])}
          </>
        )}
        {search != "" && <>{countryList(typeAHeadCounties(search))}</>}
      </div>
    );
  };

  // Render
  return (
    <div>
      {showTopLabel && label && (
        <div className={css(styles.topLabel)}>{label}</div>
      )}
      <div className={`Tel ${className}`}>
        <div
          className={`Tel__wrapper ${
            !noActiveLabel && active ? "--active" : ""
          } ${!noActiveLabel && valid ? "--valid" : ""} ${
            errors?.length >= 1 ? "--invalid" : ""
          } ${noActiveLabel ? css(styles.noActiveLabelInputWrapper) : ""} ${
            wrapperStyle ? wrapperStyle : ""
          }`}
        >
          <div className={`Tel__input-wrapper`}>
            {!noActiveLabel && (
              <label
                className={`${
                  inlineFunnel && !activeLabel ? "--inline-funnel" : ""
                } ${activeLabel ? "--active" : ""}`}
                htmlFor={internalID}
              >
                {label}
              </label>
            )}
            <input
              id={internalID}
              ref={inputEl}
              name={name}
              type={"tel"}
              onClick={handleClick}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={internalValue}
              autoComplete={autoComplete}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onChange={handleChange}
              required={required}
              onKeyDown={handleKeys}
              onPaste={handlePaste}
              className={
                css(noActiveLabel && styles.noActiveLabel) +
                " " +
                (inputStyle ? inputStyle : "")
              }
            />
          </div>
          <button
            className={`Tel__button ${
              dropdownActive ? "--active" : ""
            } ${buttonStyle}`}
            ref={dropdownButtonEl}
            onClick={handleButton}
            tabIndex={"-1"}
            type="button"
          >
            {country.emoji}
          </button>
        </div>
        {errors && errors.length > 0 && (
          <ul className="Input__errors">
            {errors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
        )}
        {countrySelector()}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  topLabel: {
    marginBottom: 8,
    fontWeight: 500,
  },
  noActiveLabel: {
    padding: "20px 15px",
    height: "100%",
    border: 0,
    background: "#fff",
  },
  noActiveLabelInputWrapper: {
    boxShadow: "rgba(30, 41, 59, 0.2) 0px 0px 28px",
    border: "none",

    ":hover": {
      border: "none",
    },
  },
});

Tel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  initialCountry: PropTypes.string,
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  setPhoneCountry: PropTypes.func,
  checkValidation: PropTypes.bool,
  inputRef: PropTypes.object,
  onFocus: PropTypes.func,
  setPhoneIsValid: PropTypes.func,
  doNotValidate: PropTypes.bool,
};

Tel.defaultProps = {
  label: "Phone",
  className: "",
  name: null,
  id: null,
  initialCountry: "US",
  preferredCountries: ["US", "CA"],
  autoComplete: "off",
  autoFocus: null,
  required: false,
  value: "",
  onChange: (e, value) => {},
  setPhoneCountry: () => {},
  customErrorMessage: ({ errorMessages }) => {
    return errorMessages;
  },
  customValidation: ({}) => {
    return true;
  },
  checkValidation: false,
  inputRef: null,
  onFocus: () => {},
  setPhoneIsValid: () => {},
  doNotValidate: false,
};

export default Tel;
