import Link from "next/link";
import Image from "next/image";
import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer({}) {
  return (
    <footer id={"footer"} className={styles["Footer"]}>
      <div className={styles["Footer__container"]}>
        <div className={styles["Footer__top"]}>
          <div className={styles["Footer__logo"]}>
            <Link href={"/"} prefetch={false}>
              <Image
                src="/static/rea_logo_full_white.webp"
                alt="realestateagents.com logo"
                width={298}
                height={50}
              />
            </Link>
          </div>
          <div className={styles["Footer__text"]}>
            <div className={styles["Footer__info"]}>
              <a href="tel:+18885862349">
                <FontAwesomeIcon
                  icon={["solid", "phone-alt"]}
                  className={styles["Footer__fa-icon"]}
                />{" "}
                (888) 586-2349
              </a>
              <span>
                <FontAwesomeIcon
                  icon={["fa", "location-dot"]}
                  className={styles["Footer__fa-icon"]}
                />
                PO BOX 3634 Seattle, WA 98124-3634
              </span>
            </div>
            <div className={styles["Footer__social"]}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/realestateagentsdotcom"
                aria-label="Follow realestateagents.com on Instagram"
              >
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  className={styles["Footer__social-icons"]}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/realestateagentsdotcom"
                aria-label="Follow realestateagents.com on Facebook"
              >
                <FontAwesomeIcon
                  icon={["fab", "facebook-f"]}
                  className={styles["Footer__social-icons"]}
                />
              </a>
            </div>
          </div>
          <div className={styles["Footer__info-mobile"]}>
            <a href="tel:+18885862349">
              <FontAwesomeIcon
                icon={["solid", "phone-alt"]}
                className={styles["Footer__fa-icon"]}
              />
              (888) 586-2349
            </a>
            <span>
              <FontAwesomeIcon
                icon={["fa", "location-dot"]}
                className={styles["Footer__fa-icon"]}
              />
              PO BOX 3634 Seattle, WA 98124-3634
            </span>
          </div>
        </div>
        <hr />
        <div className={styles["Footer__grid"]}>
          <div className={styles["Footer__grid-item"]}>
            <div className={styles["Footer__grid-item-heading"]}>
              For Buyers
            </div>
            <ul className={styles["Footer__grid-item-list"]}>
              <li>
                <Link href={"/buy"} prefetch={false}>
                  Buyers Home
                </Link>
              </li>
              <li>
                <Link href={"/blog/buying/"} prefetch={false}>
                  Buyers Blog
                </Link>
              </li>
              <li>
                <Link href={"/faq/for-buyers"} prefetch={false}>
                  Buying a Home FAQ
                </Link>
              </li>
              <li>
                <Link href={"/working-with-an-agent"} prefetch={false}>
                  Working with a Real Estate Agent
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-find-a-real-estate-agent"}
                  prefetch={false}
                >
                  How to Find a Real Estate Agent
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-compare-real-estate-agents"}
                  prefetch={false}
                >
                  How to Compare Real Estate Agents
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-a-real-estate-agent-works-for-you"}
                  prefetch={false}
                >
                  How a Real Estate Agent Works for You
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-compare-real-estate-agents"}
                  prefetch={false}
                >
                  How to Find a Real Estate Agent
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-a-real-estate-agent-works-for-you"}
                  prefetch={false}
                >
                  How a Real Estate Agent Works for You
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-find-homes-for-sale-near-you"}
                  prefetch={false}
                >
                  How to Find Homes for Sale Near You
                </Link>
              </li>
              <li>
                <Link href={"/should-you-rent-or-buy-a-home"} prefetch={false}>
                  Should You Rent or Buy a Home
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles["Footer__grid-item"]}>
            <div className={styles["Footer__grid-item-heading"]}>
              For Sellers
            </div>
            <ul className={styles["Footer__grid-item-list"]}>
              <li>
                <Link href={"/sell"} prefetch={false}>
                  Sellers Home
                </Link>
              </li>
              <li>
                <Link href={"/blog/selling/"} prefetch={false}>
                  Sellers Blog
                </Link>
              </li>
              <li>
                <Link href={"/faq/for-sellers"} prefetch={false}>
                  Listing Your Home FAQ
                </Link>
              </li>
              <li>
                <Link href={"/working-with-an-agent"} prefetch={false}>
                  Working with a Real Estate Agent
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-find-a-real-estate-agent"}
                  prefetch={false}
                >
                  How to Find a Real Estate Agent
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-compare-real-estate-agents"}
                  prefetch={false}
                >
                  How to Compare Real Estate Agents
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-a-real-estate-agent-works-for-you"}
                  prefetch={false}
                >
                  How a Real Estate Agent Works for You
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-compare-real-estate-agents"}
                  prefetch={false}
                >
                  How to Find a Real Estate Agent
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-a-real-estate-agent-works-for-you"}
                  prefetch={false}
                >
                  How a Real Estate Agent Works for You
                </Link>
              </li>
              <li>
                <Link
                  href={"/how-to-find-homes-for-sale-near-you"}
                  prefetch={false}
                >
                  How to Find Homes for Sale Near You
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles["Footer__grid-item"]}>
            <div className={styles["Footer__grid-item-heading"]}>
              Working with Agents
            </div>
            <ul className={styles["Footer__grid-item-list"]}>
              <li>
                <a href="/real-estate-guides/">Guides</a>
              </li>
              <li>
                <Link href={"/faq/working-with-an-agent"} prefetch={false}>
                  Working with Agents FAQ
                </Link>
              </li>
              <li>
                <Link href={"/agents-directory"} prefetch={false}>
                  Agent Directory
                </Link>
              </li>
              <li>
                <Link href={"/state-directory"} prefetch={false}>
                  State Directory
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles["Footer__grid-item"]}>
            <div className={styles["Footer__grid-item-heading"]}>
              For Agents
            </div>
            <ul className={styles["Footer__grid-item-list"]}>
              <li>
                <Link href={"/prime"} prefetch={false}>
                  PRIME
                </Link>
              </li>
              <li>
                <Link href={"/personal-relocation-network"} prefetch={false}>
                  Personal Relocation Network
                </Link>
              </li>
              <li>
                <Link href={"/blog/agent/"} prefetch={false}>
                  Agents Blog
                </Link>
              </li>
              <li>
                <Link href={"/faq/for-agents"} prefetch={false}>
                  FAQ for Agents
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles["Footer__grid-item"]}>
            <div className={styles["Footer__grid-item-heading"]}>About</div>
            <ul className={styles["Footer__grid-item-list"]}>
              <li>
                <Link href={"/about"} prefetch={false}>
                  About Us
                </Link>
              </li>
              <li>
                <Link href={"/contact"} prefetch={false}>
                  Contact
                </Link>
              </li>
              <li>
                <Link href={"/blog"} prefetch={false}>
                  Blog
                </Link>
              </li>
              <li>
                <Link href={"/real-estate-guides/"} prefetch={false}>
                  Guides
                </Link>
              </li>
              <li>
                <Link href={"/articles"} prefetch={false}>
                  Articles
                </Link>
              </li>
              <li>
                <Link href={"/reviews"} prefetch={false}>
                  Reviews
                </Link>
              </li>
              <li>
                <Link href={"/how-it-works"} prefetch={false}>
                  How It Works
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles["Footer__grid-item"]}>
            <div className={styles["Footer__grid-item-heading"]}>FAQ</div>
            <ul className={styles["Footer__grid-item-list"]}>
              <li>
                <Link href={"/faq/how-we-work"} prefetch={false}>
                  How We Work
                </Link>
              </li>
              <li>
                <Link href={"/faq"} prefetch={false}>
                  FAQ page
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles["Footer__logos"]}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.shopperapproved.com/reviews/RealEstateAgents.com"
            aria-label="Shopper Approved Profile"
          >
            <Image
              src="/static/images/shopper-logo.png"
              alt="Shopper Approved Logo"
              width={39}
              height={29}
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bbb.org/us/ca/san-francisco/profile/marketing-programs/referralexchange-inc-1116-458879"
            aria-label="BBB Profile"
          >
            <Image
              src="/static/images/bbb-rating-a.png"
              alt="BBB Logo"
              width={75}
              height={29}
            />
          </a>
        </div>
        <hr />
        <div className={styles["Footer__bottom"]}>
          <div className={styles["Footer__copy-right"]}>
            © {new Date().getFullYear()} RealEstateAgents.com. All rights
            reserved. DRE License #01426453
          </div>
          <ul className={styles["Footer__bottom-links"]}>
            <li>
              <a href="/privacy" target="_blank">
                Privacy
              </a>
            </li>
            <li>
              <a href="/tos" target="_blank">
                Terms
              </a>
            </li>
            <li>
              <a href="/do-not-call" target="_blank">
                Do Not Call Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.referralexchange.com/information"
                target="_blank"
              >
                Your Privacy Choices
              </a>
            </li>
            <li className={styles["Footer__privacy"]}>
              <a href="/privacy">
                <Image
                  src="/static/privacyoptions29x14.png"
                  alt="California Privacy Notice"
                  width={29}
                  height={14}
                  fill={false}
                />{" "}
                California Privacy Notice
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
